<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <form role="form">
        <div class="form-group">
          <label>Nazwa</label>
          <input
            type="text"
            class="form-control"
            v-model="category.name"
            name="category_name"
          />
          <small v-show="errors.has('category_name')" class="text-danger">
            Pole wymagane!
          </small>
        </div>
      </form>
      <div class="row">
        <div class="col-12">
          <div @click="saveChange" class="btn btn-primary">
            Zapisz
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrainingCategoriesForm",
  data() {
    return {
      category: {
        name: ""
      }
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.$route.params.id != undefined) {
        this.$emit("loading", true);
        let url = `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/trainings/training-category/${this.$route.params.id}`;
        this.$http
          .get(url)
          .then(resp => {
            this.category = resp.data;
            this.$emit("loading", false);
          })
          .catch(err => {
            this.$toastr.error(err);
            this.$emit("loading", false);
          });
      }
      this.$emit("loading", false);
    },
    saveChange() {
      this.$emit("loading", true);
      let url = `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/trainings/training-category`;

      this.$http
        .customRequest({
          method: this.$route.params.id !== undefined ? "put" : "post",
          url:
            this.$route.params.id !== undefined
              ? `${url}/${this.$route.params.id}`
              : url,
          data: this.category
        })
        .then(() => {
          // console.log(resp);
          this.$toastr.success("Zrobione!");
          this.$emit("loading", false);
        })
        .catch(err => {
          this.$toastr.error(err);
        });
    }
  }
};
</script>
